@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap");

.App {
  text-align: center;
  background-color: #141414;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  background-color: rgb(12, 12, 12);
  font-family: "M PLUS Rounded 1c", sans-serif;
}
svg.togglebutton {
  display: none;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid rgb(68, 61, 71);
  margin: 0;
  padding: 0;
  box-shadow: 0px 6px 7px 0px rgb(185, 185, 185);
}
.divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(161, 161, 161, 0.342);
  margin: 0;
  padding: 0;
}

.herader-btn {
  border-top-color: olivedrab;
  border-bottom: 2px solid #ec9b3b;
}

.box1 {
  margin-bottom: 20px;
}
.topbutn {
  width: 300px;
}

.btn-1 {
  background-color: #e4bf34;
  color: rgb(226, 226, 226);
  border-radius: 5px;
  margin: 1px;
  padding: 0px 5px;
}

.btn-1:hover {
  background-color: rgba(124, 124, 12, 0.781);
  color: white;
}

.headata {
  background-color: #ffca2c;
  color: rgb(0, 0, 0);
  border: 5px;
}

.price-box {
  padding: 5px;
}
.price-box input {
  outline: none;
  background-color: transparent;
  color: aliceblue;
}

.box1 input {
  outline: none;
  background-color: transparent;
  color: aliceblue;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.suggestionBurn {
  border: 1px solid #f24c4c;
  color: #f24c4c;
}

.suggestionMint {
  border: 1px solid #36ae7c;
  color: #36ae7c;
}

footer {
  height: fit-content;
  width: 100%;
}

.foot-container {
  min-height: 100%;
  margin-top: auto;
}

.diffbg {
  background-color: #ff5b00;
  font-weight: 700;
  color: #fff;
}

.price {
  border: 1px solid rgb(114, 112, 112);
  padding: 2px 10px 2px 10px;
}

footer p {
  text-align: center;
  margin: 0 auto;
  color: #fff;
  padding: 10px 0;
}

footer p a {
  color: #ffca2c;
}

.inputHarvest {
  border: 1px solid #4b4b4b;
  margin: 25px auto auto auto;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  outline: none;
  background-color: #1f1f1f;
}

/* LOGIN FORM */
.login-container {
  min-height: 100vh;
  width: 100%;
}
.login {
  min-width: 300px;
  max-width: 450px;
  padding: 25px;
  max-height: 500px;
  background-color: #1f1f1f;
}
.switchclass {
  display: flex;
}

.effectivemmk {
  text-align: center;
  background-color: #27aa7c;
  color: #fff;
  font-weight: 700;
  padding: 15px 0px;
}

.logindata {
  background-color: #000000;
  padding: 20px !important;
  border-bottom: 0.7px #fff solid;
}
/* .row.responsive {
  display: none;
}

.row.resposnesiveheader {
  display: none;
} */

@media screen and (max-width: 767px) {
  svg.togglebutton {
    display: block;
    font-size: 30px;
    color: #fff;
  }
  .switchclass {
    display: none;
  }
  #switcharea {
    display: grid;
  }
  .p-2.d-flex.justify-content-evenly.flex-column.flex-md-row {
    display: grid !important;
  }
  .p-3.d-flex.justify-content-evenly.flex-column.flex-md-row {
    display: grid !important;
  }
  .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;

    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    margin-left: auto;
  }
  .d-flex.justify-content-center.nav345 {
    display: none !important;
  }
  .row.responsive {
    display: block;
    background-color: #000;
    margin-bottom: 100px;
  }
  .btn-warning {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
    margin-top: 10px;
    width: 65%;
  }
  .p-2.d-flex.justify-content-evenly.flex-column.flex-md-row.resposnesiveheader {
    display: none !important;
  }
}
@media screen and (max-width: 940px) and (min-width: 768px) {
  .p-2.d-flex.justify-content-evenly.flex-column.flex-md-row.resposnesiveheader {
    display: none !important;
  }
  .headata {
    background-color: #ffca2c;
    color: rgb(0, 0, 0);
    border: 5px;
    font-size: 14px;
  }
  .diffbg {
    background-color: #ff5b00;
    font-weight: 700;
    color: #fff;
    font-size: 14px;
  }
  .diffbg2 {
    background-color: #ff5b00;
    font-weight: 700;
    color: #fff;
    font-size: 13px;
  }
  .topbutn {
    width: 300px;
    margin: 0px 15px;
  }
}
